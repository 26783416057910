import React, { useEffect } from "react";
import {
  Row,
  Container,
  Col,
  Form,
  Button,
  Stack,
  Alert,
} from "react-bootstrap";
import "./App.css";
import { Link } from "react-router-dom";

const First = () => {
    useEffect(()=> {
        window.document.title='提货须知';
    },[]);

  return (
  <Container className="p-3 bg">
    <div>
        <p className="title">苏浔蟹礼大闸蟹提货告知书</p>
        <p>尊敬的客户：您好，感谢您对苏浔蟹礼的支持与信任。</p>
        <p>2024年度大闸蟹提货期即将告一段落，现就大闸蟹提货安排做如下说明告知：</p>
        <p>1：本年度大闸蟹后台发货已停止预约，后台将关闭进行维护升级。</p>
        <p>2：礼券永不过期，永不作废，未使用的礼券待至明年大闸蟹提货期均可正常提货。</p>
        <p>带来不便，敬请谅解!</p>
        <br/><br/>
        <p>如有疑问可咨询我司客服电话4001608088   (09:00-18:00)</p>
    </div>
    {/* <div className="w-100 d-flex justify-content-center">
        <Button className="w-100" href="#second" variant="primary">我已知晓，开始提货</Button>
    </div> */}
  </Container>)
};

export default First;
